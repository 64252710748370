import React from 'react';

// Assets
import cisLogo from '../assets/compliance-standards-icons/cis-badge.png';
import cisLogo2x from '../assets/compliance-standards-icons/cis-badge@2x.png';
import cisLogo3x from '../assets/compliance-standards-icons/cis-badge@3x.png';
import csaStarLogo from '../assets/compliance-standards-icons/csa-star-badge.png';
import csaStarLogo2x from '../assets/compliance-standards-icons/csa-star-badge@2x.png';
import csaStarLogo3x from '../assets/compliance-standards-icons/csa-star-badge@3x.png';
import cmmcLogo from '../assets/compliance-standards-icons/cmmc-badge.png';
import cmmcLogo2x from '../assets/compliance-standards-icons/cmmc-badge@2x.png';
import cmmcLogo3x from '../assets/compliance-standards-icons/cmmc-badge@3x.png';
import cmmcl2Logo from '../assets/compliance-standards-icons/cmmc-l2-badge.png';
import cmmcl2Logo2x from '../assets/compliance-standards-icons/cmmc-l2-badge@2x.png';
import cmmcl2Logo3x from '../assets/compliance-standards-icons/cmmc-l2-badge@3x.png';
import iso9001Logo from '../assets/compliance-standards-icons/iso9001-badge.png';
import iso9001Logo2x from '../assets/compliance-standards-icons/iso9001-badge@2x.png';
import iso9001Logo3x from '../assets/compliance-standards-icons/iso9001-badge@3x.png';
import iso27017logo from '../assets/compliance-standards-icons/iso27017-badge.png';
import iso27017logo2x from '../assets/compliance-standards-icons/iso27017-badge@2x.png';
import iso27017logo3x from '../assets/compliance-standards-icons/iso27017-badge@3x.png';
import iso27001Logo from '../assets/compliance-standards-icons/iso27001-badge-simple.png';
import iso27001Logo2x from '../assets/compliance-standards-icons/iso27001-badge-simple@2x.png';
import iso27001Logo3x from '../assets/compliance-standards-icons/iso27001-badge-simple@3x.png';
import iso27018Logo from '../assets/compliance-standards-icons/iso27018-badge.png';
import iso27018Logo2x from '../assets/compliance-standards-icons/iso27018-badge@2x.png';
import iso27018Logo3x from '../assets/compliance-standards-icons/iso27018-badge@3x.png';
import iso27701Logo from '../assets/compliance-standards-icons/iso27701-badge.png';
import iso27701Logo2x from '../assets/compliance-standards-icons/iso27701-badge@2x.png';
import iso27701Logo3x from '../assets/compliance-standards-icons/iso27701-badge@3x.png';
import hipaaLogo from '../assets/compliance-standards-icons/hipaa-badge-simple.png';
import hipaaLogo2x from '../assets/compliance-standards-icons/hipaa-badge-simple@2x.png';
import hipaaLogo3x from '../assets/compliance-standards-icons/hipaa-badge-simple@3x.png';
import gdprLogo from '../assets/compliance-standards-icons/gdpr-badge.png';
import gdprLogo2x from '../assets/compliance-standards-icons/gdpr-badge@2x.png';
import gdprLogo3x from '../assets/compliance-standards-icons/gdpr-badge@3x.png';
import soc1Logo from '../assets/compliance-standards-icons/soc1-badge.png';
import soc1Logo2x from '../assets/compliance-standards-icons/soc1-badge@2x.png';
import soc1Logo3x from '../assets/compliance-standards-icons/soc1-badge@3x.png';
import soc2PrivacyLogo from '../assets/compliance-standards-icons/soc2-privacy-badge.png';
import soc2PrivacyLogo2x from '../assets/compliance-standards-icons/soc2-privacy-badge@2x.png';
import soc2PrivacyLogo3x from '../assets/compliance-standards-icons/soc2-privacy-badge@3x.png';
import soc2TypeOneLogo from '../assets/compliance-standards-icons/soc2-type1-badge.png';
import soc2TypeOne2x from '../assets/compliance-standards-icons/soc2-type1-badge@2x.png';
import soc2TypeOne3x from '../assets/compliance-standards-icons/soc2-type1-badge@3x.png';
import soc2TypeTwoLogo from '../assets/compliance-standards-icons/soc2-type2-badge.png';
import soc2TypeTwo2x from '../assets/compliance-standards-icons/soc2-type2-badge@2x.png';
import soc2TypeTwo3x from '../assets/compliance-standards-icons/soc2-type2-badge@3x.png';
import soc2GenericLogo from '../assets/compliance-standards-icons/soc2-badge-simple.png';
import soc2Generic2x from '../assets/compliance-standards-icons/soc2-badge-simple@2x.png';
import soc2Generic3x from '../assets/compliance-standards-icons/soc2-badge-simple@3x.png';
import ccpaLogo from '../assets/compliance-standards-icons/ccpa-badge.png';
import ccpaLogo2x from '../assets/compliance-standards-icons/ccpa-badge@2x.png';
import ccpaLogo3X from '../assets/compliance-standards-icons/ccpa-badge@3x.png';
import soc3Logo from '../assets/compliance-standards-icons/soc3-badge.png';
import soc3Logo2x from '../assets/compliance-standards-icons/soc3-badge@2x.png';
import soc3Logo3x from '../assets/compliance-standards-icons/soc3-badge@3x.png';
import soxLogo from '../assets/compliance-standards-icons/sox-badge.png';
import soxLogo2x from '../assets/compliance-standards-icons/sox-badge@2x.png';
import soxLogo3x from '../assets/compliance-standards-icons/sox-badge@3x.png';
import fedrampLogo from '../assets/compliance-standards-icons/fedramp-badge.png';
import fedrampLogo2x from '../assets/compliance-standards-icons/fedramp-badge@2x.png';
import fedrampLogo3x from '../assets/compliance-standards-icons/fedramp-badge@3x.png';
import nistLogo from '../assets/compliance-standards-icons/nist-800-53-badge.png';
import nistLogo2x from '../assets/compliance-standards-icons/nist-800-53-badge@2x.png';
import nistLogo3x from '../assets/compliance-standards-icons/nist-800-53-badge@3x.png';
import hitrustLogo from '../assets/compliance-standards-icons/hitrust-badge.png';
import hitrustLogo2x from '../assets/compliance-standards-icons/hitrust-badge@2x.png';
import hitrustLogo3x from '../assets/compliance-standards-icons/hitrust-badge@3x.png';
import pciDssLogo from '../assets/compliance-standards-icons/pci-dss-badge.png';
import pciDssLogo2x from '../assets/compliance-standards-icons/pci-dss-badge@2x.png';
import pciDssLogo3x from '../assets/compliance-standards-icons/pci-dss-badge@3x.png';
import privacyShieldLogo from '../assets/compliance-standards-icons/privacy-shield-badge.png';
import privacyShieldLogo2x from '../assets/compliance-standards-icons/privacy-shield-badge@2x.png';
import privacyShieldLogo3x from '../assets/compliance-standards-icons/privacy-shield-badge@3x.png';
import nistCsfLogo from '../assets/compliance-standards-icons/nist-csf-badge.png';
import nistCsfLogo2x from '../assets/compliance-standards-icons/nist-csf-badge@2x.png';
import nistCsfLogo3x from '../assets/compliance-standards-icons/nist-csf-badge@3x.png';
import nistSp800171Logo from '../assets/compliance-standards-icons/nist-sp-800-171-badge.png';
import nistSp800171Logo2x from '../assets/compliance-standards-icons/nist-sp-800-171-badge@2x.png';
import nistSp800171Logo3x from '../assets/compliance-standards-icons/nist-sp-800-171-badge@3x.png';
import cyberEssentialsBadge from '../assets/compliance-standards-icons/cyber-essentials-badge.png';
import cyberEssentialsBadge2x from '../assets/compliance-standards-icons/cyber-essentials-badge@2x.png';
import cyberEssentialsBadge3x from '../assets/compliance-standards-icons/cyber-essentials-badge@3x.png';
import iso27701ProcessorLogo from '../assets/compliance-standards-icons/iso27701-processor-badge.png';
import iso27701ProcessorLogo2x from '../assets/compliance-standards-icons/iso27701-processor-badge@2x.png';
import iso27701ProcessorLogo3x from '../assets/compliance-standards-icons/iso27701-processor-badge@3x.png';
import iso27701ControllerLogo from '../assets/compliance-standards-icons/iso27701-controller-badge.png';
import iso27701ControllerLogo2x from '../assets/compliance-standards-icons/iso27701-controller-badge@2x.png';
import iso27701ControllerLogo3x from '../assets/compliance-standards-icons/iso27701-controller-badge@3x.png';
import iso270012022Logo from '../assets/compliance-standards-icons/iso27001-2022-badge.png';
import iso270012022Logo2x from '../assets/compliance-standards-icons/iso27001-2022-badge@2x.png';
import iso270012022Logo3x from '../assets/compliance-standards-icons/iso27001-2022-badge@3x.png';
import gdprPrivacyLogo from '../assets/compliance-standards-icons/gdpr-privacy-badge.png'; // GDPR TODO
import gdprPrivacyLogo2x from '../assets/compliance-standards-icons/gdpr-privacy-badge@2x.png';
import gdprPrivacyLogo3x from '../assets/compliance-standards-icons/gdpr-privacy-badge@3x.png';
import dataPrivacyFrameworkLogo from '../assets/compliance-standards-icons/data-privacy-framework-badge.png';
import iso42001Logo from '../assets/compliance-standards-icons/iso42001-badge.png';
import iso42001Logo2x from '../assets/compliance-standards-icons/iso42001-badge@2x.png';
import iso42001Logo3x from '../assets/compliance-standards-icons/iso42001-badge@3x.png';
import nistAiRmfLogo from '../assets/compliance-standards-icons/nist-ai-rmf-badge.png';
import nistAiRmfLogo2x from '../assets/compliance-standards-icons/nist-ai-rmf-badge@2x.png';
import nistAiRmfLogo3x from '../assets/compliance-standards-icons/nist-ai-rmf-badge@3x.png';

export const SENTRY_DSN = 'https://9fae77f4b0964d2eaf3910ef53d0c97d@o1294231.ingest.sentry.io/6518072';

export const GOOGLE_SSO_CLIENT_ID = '81502682613-08hh3m47iumv7df50fo9m70rmnct5hmg.apps.googleusercontent.com';
export const GOOGLE_SSO_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth?';
export const GOOGLE_SSO_NONCE_STORAGE_KEY = 'sso.google.nonce';

export const MICROSOFT_SSO_CLIENT_ID = '6575c309-522c-43a6-a61a-1bc00ed48116';
export const MICROSOFT_SSO_ENDPOINT = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?';

export const FACEBOOK_APP_ID = '418566062440089';
export const HONEYCOMB_API_KEY = process.env.REACT_APP_HONEYCOMB_API_KEY;
export const HONEYCOMB_SERVICE_NAME = 'trustshare-live';

export const SSO_PROVIDER = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
};

export const SSO_TOKEN_PARAM = {
  [SSO_PROVIDER.GOOGLE]: 'id_token',
  [SSO_PROVIDER.MICROSOFT]: 'access_token',
};

export const HOST = process.env.REACT_APP_API_HOST || 'http://localhost:7007';
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || 'a6de2a37-b5bf-48dd-8512-054c093fd77b';
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
  ? atob(process.env.REACT_APP_CLIENT_SECRET)
  : "h?Ss(Xz6XJ(y$TF<yN*LvFcv$KIpQs+>gVTPL}Sm8e'hJ2(21n/=wqq)^=2_5Sn";
export const DEFAULT_STALE_TIME = 60 * 60 * 1000;

export const DATA_CLASSIFICATION = {
  CUSTOMER_CONFIDENTIAL: 'customer_confidential',
  COMPANY_RESTRICTED: 'company_restricted',
  COMPANY_CONFIDENTIAL: 'company_confidential',
  PUBLIC: 'public',
};

export const POLICY_SECURITY_GROUPS = {
  APPLICATION_SECURITY: 'Application Security',
  DATA_SECURITY_PRIVACY: 'Data Security and Privacy',
  INFRASTRUCTURE_SECURITY: 'Infrastructure Security',
  SECURITY_OPERATIONS: 'Security Operations',
};

export const COMPLIANCE_STANDARD_GROUPS = {
  INFOSEC_COMPLIANCE: 'infosec_compliance',
  PRIVACY: 'privacy',
  QUALITY: 'quality',
};

export const COMPLIANCE_STANDARD_GROUP_NAME = {
  [COMPLIANCE_STANDARD_GROUPS.INFOSEC_COMPLIANCE]: 'InfoSec Compliance',
  [COMPLIANCE_STANDARD_GROUPS.PRIVACY]: 'Privacy',
  [COMPLIANCE_STANDARD_GROUPS.QUALITY]: 'Quality',
};

export const SOC_SUBTYPE = {
  TYPE_1: 'type_1',
  TYPE_2: 'type_2',
  GENERIC: 'generic',
};

export const SOC_SUBTYPE_DISPLAY_NAME = {
  [SOC_SUBTYPE.GENERIC]: '',
  [SOC_SUBTYPE.TYPE_1]: 'Type I',
  [SOC_SUBTYPE.TYPE_2]: 'Type II',
};

export const COMPLIANCE_STANDARDS = {
  CIS: 'cis',
  CSA_STAR_L1: 'csa_star_l1',
  CSA_STAR_L2: 'csa_star_l2',
  CSA_STAR_L3: 'csa_star_l3',
  CMMC_L1: 'cmmc_l1',
  CMMC_L2: 'cmmc_l2',
  FEDRAMP: 'fedramp',
  NIST_800_53: 'nist_800_53',
  SOC1: 'soc1',
  SOC2: 'soc2',
  SOC2_PRIVACY: 'soc2_privacy',
  SOC3: 'soc3',
  SOX: 'sox',
  ISO9001: 'iso9001',
  ISO27001: 'iso27001',
  ISO27017: 'iso27017',
  ISO27018: 'iso27018',
  ISO27701: 'iso27701',
  HIPAA: 'hipaa',
  HITRUST: 'hitrust',
  GDPR: 'gdpr',
  GDPR_PRIVACY: 'gdpr_privacy',
  CCPA: 'ccpa',
  PCI_DSS: 'pci_dss',
  PRIVACY_SHIELD: 'privacy_shield',
  NIST_CSF: 'nist_csf',
  NIST_SP_800_171: 'nist_sp_800_171',
  CYBER_ESSENTIALS: 'cyber_essentials',
  ISO_27701_PROCESSOR: 'iso27701_processor',
  ISO_27701_CONTROLLER: 'iso27701_controller',
  ISO_27001_2022: 'iso27001_2022',
  SOX_ITGC: 'sox_itgc',
  DATA_PRIVACY_FRAMEWORK: 'data_privacy_framework',
  ISO42001: 'iso42001',
  NIST_AI_RMF: 'nist_ai_rmf',
};

export const COMPLIANCE_STANDARD_DISPLAY_NAME = {
  [COMPLIANCE_STANDARDS.CIS]: 'CIS',
  [COMPLIANCE_STANDARDS.CSA_STAR_L1]: 'CSA STAR Level 1',
  [COMPLIANCE_STANDARDS.CSA_STAR_L2]: 'CSA STAR Level 2',
  [COMPLIANCE_STANDARDS.CSA_STAR_L3]: 'CSA STAR Level 3',
  [COMPLIANCE_STANDARDS.CMMC_L1]: 'CMMC Level 1',
  [COMPLIANCE_STANDARDS.CMMC_L2]: 'CMMC Level 2',
  [COMPLIANCE_STANDARDS.FEDRAMP]: 'FedRAMP',
  [COMPLIANCE_STANDARDS.NIST_800_53]: 'NIST 800-53',
  [COMPLIANCE_STANDARDS.SOC1]: 'SOC 1',
  [COMPLIANCE_STANDARDS.SOC2_PRIVACY]: 'SOC 2 Privacy',
  [COMPLIANCE_STANDARDS.SOC2]: {
    [SOC_SUBTYPE.GENERIC]: 'SOC 2',
    [SOC_SUBTYPE.TYPE_1]: 'SOC 2 Type I',
    [SOC_SUBTYPE.TYPE_2]: 'SOC 2 Type II',
  },
  [COMPLIANCE_STANDARDS.SOC3]: 'SOC 3',
  [COMPLIANCE_STANDARDS.SOX]: 'SOX',
  [COMPLIANCE_STANDARDS.ISO9001]: 'ISO 9001',
  [COMPLIANCE_STANDARDS.ISO27001]: 'ISO 27001',
  [COMPLIANCE_STANDARDS.ISO27017]: 'ISO 27017',
  [COMPLIANCE_STANDARDS.ISO27018]: 'ISO 27018',
  [COMPLIANCE_STANDARDS.ISO27701]: 'ISO 27701',
  [COMPLIANCE_STANDARDS.HITRUST]: 'HITRUST',
  [COMPLIANCE_STANDARDS.HIPAA]: 'HIPAA',
  [COMPLIANCE_STANDARDS.GDPR]: 'GDPR',
  [COMPLIANCE_STANDARDS.GDPR_PRIVACY]: 'GDPR Privacy',
  [COMPLIANCE_STANDARDS.CCPA]: 'CCPA',
  [COMPLIANCE_STANDARDS.PCI_DSS]: 'PCI DSS',
  [COMPLIANCE_STANDARDS.PRIVACY_SHIELD]: 'Privacy Shield',
  [COMPLIANCE_STANDARDS.NIST_CSF]: 'NIST CSF',
  [COMPLIANCE_STANDARDS.NIST_SP_800_171]: 'NIST SP 800-171',
  [COMPLIANCE_STANDARDS.CYBER_ESSENTIALS]: 'Cyber Essentials',
  [COMPLIANCE_STANDARDS.ISO_27701_PROCESSOR]: 'ISO 27701 Processor',
  [COMPLIANCE_STANDARDS.ISO_27701_CONTROLLER]: 'ISO 27701 Controller',
  [COMPLIANCE_STANDARDS.ISO_27001_2022]: 'ISO 27001:2022',
  [COMPLIANCE_STANDARDS.DATA_PRIVACY_FRAMEWORK]: 'Data Privacy Framework',
  [COMPLIANCE_STANDARDS.ISO42001]: 'ISO 42001',
  [COMPLIANCE_STANDARDS.NIST_AI_RMF]: 'NIST AI RMF',
};

export const COMPLIANCE_STANDARD_LOGO_MAP = {
  [COMPLIANCE_STANDARDS.CIS]: cisLogo,
  [COMPLIANCE_STANDARDS.CSA_STAR_L1]: csaStarLogo,
  [COMPLIANCE_STANDARDS.CSA_STAR_L2]: csaStarLogo,
  [COMPLIANCE_STANDARDS.CSA_STAR_L3]: csaStarLogo,
  [COMPLIANCE_STANDARDS.CMMC_L1]: cmmcLogo,
  [COMPLIANCE_STANDARDS.CMMC_L2]: cmmcl2Logo,
  [COMPLIANCE_STANDARDS.FEDRAMP]: fedrampLogo,
  [COMPLIANCE_STANDARDS.NIST_800_53]: nistLogo,
  [COMPLIANCE_STANDARDS.SOC1]: soc1Logo,
  [COMPLIANCE_STANDARDS.SOC2_PRIVACY]: soc2PrivacyLogo,
  [COMPLIANCE_STANDARDS.SOC2]: {
    [SOC_SUBTYPE.GENERIC]: soc2GenericLogo, // The controls aren’t mapped to a specific subtype of SOC 2, they’re mapped to the overall SOC 2 standard. We use this mapping wherever we need to reference that.
    [SOC_SUBTYPE.TYPE_1]: soc2TypeOneLogo,
    [SOC_SUBTYPE.TYPE_2]: soc2TypeTwoLogo,
  },
  [COMPLIANCE_STANDARDS.SOC3]: soc3Logo,
  [COMPLIANCE_STANDARDS.SOX]: soxLogo,
  [COMPLIANCE_STANDARDS.ISO9001]: iso9001Logo,
  [COMPLIANCE_STANDARDS.ISO27001]: iso27001Logo,
  [COMPLIANCE_STANDARDS.ISO27017]: iso27017logo,
  [COMPLIANCE_STANDARDS.ISO27018]: iso27018Logo,
  [COMPLIANCE_STANDARDS.ISO27701]: iso27701Logo,
  [COMPLIANCE_STANDARDS.HITRUST]: hitrustLogo,
  [COMPLIANCE_STANDARDS.HIPAA]: hipaaLogo,
  [COMPLIANCE_STANDARDS.GDPR]: gdprLogo,
  [COMPLIANCE_STANDARDS.CCPA]: ccpaLogo,
  [COMPLIANCE_STANDARDS.PCI_DSS]: pciDssLogo,
  [COMPLIANCE_STANDARDS.PRIVACY_SHIELD]: privacyShieldLogo,
  [COMPLIANCE_STANDARDS.NIST_CSF]: nistCsfLogo,
  [COMPLIANCE_STANDARDS.NIST_SP_800_171]: nistSp800171Logo,
  [COMPLIANCE_STANDARDS.CYBER_ESSENTIALS]: cyberEssentialsBadge,
  [COMPLIANCE_STANDARDS.ISO_27701_PROCESSOR]: iso27701ProcessorLogo,
  [COMPLIANCE_STANDARDS.ISO_27701_CONTROLLER]: iso27701ControllerLogo,
  [COMPLIANCE_STANDARDS.ISO_27001_2022]: iso270012022Logo,
  [COMPLIANCE_STANDARDS.GDPR_PRIVACY]: gdprPrivacyLogo,
  [COMPLIANCE_STANDARDS.DATA_PRIVACY_FRAMEWORK]: dataPrivacyFrameworkLogo,
  [COMPLIANCE_STANDARDS.ISO42001]: iso42001Logo,
  [COMPLIANCE_STANDARDS.NIST_AI_RMF]: nistAiRmfLogo,
};

export const COMPLIANCE_STANDARD_SRCSET_MAP = {
  [COMPLIANCE_STANDARDS.CIS]: `${cisLogo2x} 2x, ${cisLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.CSA_STAR_L1]: `${csaStarLogo2x} 2x, ${csaStarLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.CSA_STAR_L2]: `${csaStarLogo2x} 2x, ${csaStarLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.CSA_STAR_L3]: `${csaStarLogo2x} 2x, ${csaStarLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.CMMC_L1]: `${cmmcLogo2x} 2x, ${cmmcLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.CMMC_L2]: `${cmmcl2Logo2x} 2x, ${cmmcl2Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.FEDRAMP]: `${fedrampLogo2x} 2x, ${fedrampLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.NIST_800_53]: `${nistLogo2x} 2x, ${nistLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.SOC1]: `${soc1Logo2x} 2x, ${soc1Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.SOC2_PRIVACY]: `${soc2PrivacyLogo2x} 2x, ${soc2PrivacyLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.SOC2]: {
    [SOC_SUBTYPE.GENERIC]: `${soc2Generic2x} 2x, ${soc2Generic3x} 3x`,
    [SOC_SUBTYPE.TYPE_1]: `${soc2TypeOne2x} 2x, ${soc2TypeOne3x} 3x`,
    [SOC_SUBTYPE.TYPE_2]: `${soc2TypeTwo2x} 2x, ${soc2TypeTwo3x} 3x`,
  },
  [COMPLIANCE_STANDARDS.SOC3]: `${soc3Logo2x} 2x, ${soc3Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.SOX]: `${soxLogo2x} 2x, ${soxLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO9001]: `${iso9001Logo2x} 2x, ${iso9001Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO27001]: `${iso27001Logo2x} 2x, ${iso27001Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO27017]: `${iso27017logo2x} 2x, ${iso27017logo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO27018]: `${iso27018Logo2x} 2x, ${iso27018Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO27701]: `${iso27701Logo2x} 2x, ${iso27701Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.HITRUST]: `${hitrustLogo2x} 2x, ${hitrustLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.HIPAA]: `${hipaaLogo2x} 2x, ${hipaaLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.GDPR]: `${gdprLogo2x} 2x, ${gdprLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.CCPA]: `${ccpaLogo2x} 2x, ${ccpaLogo3X} 3x`,
  [COMPLIANCE_STANDARDS.PCI_DSS]: `${pciDssLogo2x} 2x, ${pciDssLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.PRIVACY_SHIELD]: `${privacyShieldLogo2x} 2x, ${privacyShieldLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.NIST_CSF]: `${nistCsfLogo2x} 2x, ${nistCsfLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.NIST_SP_800_171]: `${nistSp800171Logo2x} 2x, ${nistSp800171Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.CYBER_ESSENTIALS]: `${cyberEssentialsBadge2x} 2x, ${cyberEssentialsBadge3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO_27701_PROCESSOR]: `${iso27701ProcessorLogo2x} 2x, ${iso27701ProcessorLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO_27701_CONTROLLER]: `${iso27701ControllerLogo2x} 2x, ${iso27701ControllerLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO_27001_2022]: `${iso270012022Logo2x} 2x, ${iso270012022Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.GDPR_PRIVACY]: `${gdprPrivacyLogo2x} 2x, ${gdprPrivacyLogo3x} 3x`,
  [COMPLIANCE_STANDARDS.ISO42001]: `${iso42001Logo2x} 2x, ${iso42001Logo3x} 3x`,
  [COMPLIANCE_STANDARDS.NIST_AI_RMF]: `${nistAiRmfLogo2x} 2x, ${nistAiRmfLogo3x} 3x`,
};

export const CERTIFICATION_STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
};

export const CERTIFICATION_STATUS_DISPLAY_NAME_MAP = {
  [CERTIFICATION_STATUS.COMPLETE]: 'Achieved',
  [CERTIFICATION_STATUS.IN_PROGRESS]: 'In Progress',
};

export function getCertificationLogo(shortName, subtype) {
  if (subtype) {
    return COMPLIANCE_STANDARD_LOGO_MAP[shortName][subtype];
  }
  return COMPLIANCE_STANDARD_LOGO_MAP[shortName];
}

export function getCertificationLogoSrcSet(shortName, subtype) {
  if (subtype) {
    return COMPLIANCE_STANDARD_SRCSET_MAP[shortName][subtype];
  }
  return COMPLIANCE_STANDARD_SRCSET_MAP[shortName];
}

export function getCertificationCardTitle(shortName, subtype) {
  if (subtype) {
    return `${COMPLIANCE_STANDARD_DISPLAY_NAME[shortName][subtype]}`;
  }
  return `${COMPLIANCE_STANDARD_DISPLAY_NAME[shortName]}`;
}

export const STANDARD_QUESTIONNAIRES = {
  CAIQ: 'CAIQ',
  VSA: 'VSA',
  SIG: 'SIG',
};

export const POLICY_SECURITY_TITLE_MAP = {
  APPLICATION_SECURITY: 'Application Security Policies',
  INFRASTRUCTURE_SECURITY: 'Infrastructure Security Policies',
  SECURITY_OPERATIONS: 'Security Operations Policies',
  DATA_SECURITY_PRIVACY: 'Data Security & Privacy Policies',
};

export const POLICY_SECURITY_SUBTITLE_MAP = {
  APPLICATION_SECURITY: `
    These policies ensure that our own applications are developed and shipped in a secure fashion,
    and that access to all third-party services utilized by our company is strictly controlled.
  `,
  INFRASTRUCTURE_SECURITY: `
    These policies govern the security and resilience of the infrastructure that
    powers our products, and the tools that help manage it.
  `,
  SECURITY_OPERATIONS: `
    These policies control how we manage our security program,
    our incident response process, and the rules and diligence we have
    established for our employees.
  `,
  DATA_SECURITY_PRIVACY: `
    These policies establish processes for maintaining the security,
    confidentiality, and privacy of both our data and the data entrusted to us by our customers.
  `,
};

export const DOCUMENTS_INFO = {
  title: 'Trust, Security, and Compliance Documents',
  subtitle: `To help your security and compliance teams effortlessly understand and validate how we fit into your organization’s compliance requirements,
  we have consolidated documentation surrounding our security, privacy, and compliance program into a single, easily-accessible location.`,
};

export const CERTIFICATIONS_INFO = {
  title: 'Certifications',
  subtitle: `
    We maintain a formal compliance program aligned to industry-standard frameworks.
    You will find a list of standards and certifications that we have adopted to further demonstrate our commitment to security and privacy.
  `,
};

export const SUBPROCESSORS_INFO = {
  title: 'Subprocessors',
  subtitle: `
    We use subprocessors to store or process data on behalf of our customers.
    Before engaging any subprocessor, we perform due diligence of such subprocessor’s privacy, information security, and data protection practices.
  `,
};

export const CONTACT_US_TEXT = `
  We would be happy to share more information with you.
  To request access this to document, please fill out the form below
`;

/**
 * TrustShare exposes contact us capabilities from different pages.
 * This object map is used to keep a track of the different ways a contact request is initiated
 */
export const CONTACT_US_EMAIL_REQUEST_TYPE = {
  CERTIFICATION: 'certification',
  DOCUMENT: 'document',
  POLICY: 'policy',
  SUBPROCESSOR: 'subprocessor',
  CONTACT_US: 'contactUs',
};

export const DOCUMENT_ACCESS_LEVEL = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  DATA_ROOM: 'data_room',
  RESTRICTED: 'restricted',
};

export const RESOURCE_TYPE = {
  FILE: 'file',
  LINK: 'link',
};

export const ARTIFACT_TYPE = {
  CERTIFICATION: 'certification',
  POLICY: 'policy',
  DOCUMENT: 'document',
  QUESTIONNAIRE: 'questionnaire',
};

export const ACTIVITY_TYPE = {
  CLICK: 'click',
  DOWNLOAD: 'download',
};

export const TRUST_SHARE_ACCESS_LEVELS = {
  TRANSPARENT: 'transparent',
  PRIVATE: 'private',
};

// This is applicable for policies and controls as part of home sections as well as detailed pages
export const CONTENT_VISIBILITY_ACCESS_LEVELS = {
  TRANSPARENT: 'transparent', // visible to everyone
  PRIVATE: 'private', // visible to authenticated users
  HIDDEN: 'hidden', // hidden for all
};

export const LOGIN_METHOD = {
  SSO: 'sso',
  EMAIL: 'email',
};

export const PASSWORD_UPPERCASE_NUMBER_REGEX = /^(?=.*[A-Z])(?=.*[0-9])/;

export const COMMON_URL_ACTIONS = {
  SHOW_REQUEST_FORM: 'showRequestForm',
  SHOW_SSO_LOGIN: 'showSSOLogin',
};

export const PRIVATE_TRUST_SHARE_NAVIGATION_ITEMS = [
  {
    title: 'Home',
    path: '/home',
    exact: true,
    subMenu: [],
  },
];

export const NAVIGATION_BAR_ITEMS = [
  {
    title: 'Home',
    path: '/home',
    exact: true,
    subMenu: [],
  },
  {
    title: 'Policies',
    path: '/policies',
    exact: false,
    subMenu: [],
  },
  {
    title: 'Controls',
    path: '/controls',
    exact: true,
    subMenu: [],
  },
  {
    title: 'Compliance',
    subMenu: [
      {
        title: 'Certifications',
        path: '/certifications',
        exact: true,
      },
      {
        title: 'Subprocessors',
        path: '/subprocessors',
        exact: true,
      },
    ],
  },
  {
    title: 'Documents',
    path: '/documents',
    exact: true,
    subMenu: [],
  },
  {
    title: 'Resources',
    subMenu: [
      {
        title: 'Notification Center',
        path: '/notifications',
        exact: true,
      },
      {
        title: 'Faq',
        path: '/faq',
        exact: true,
      },
    ],
  },
];

export const DOCUMENT_TYPE = {
  RESOURCE: 'resource',
  CERTIFICATION: 'certification',
  POLICY: 'policy',
  QUESTIONNAIRE: 'questionnaire',
  SHARED_WITH_YOU: 'shared_with_only_you',
};

export const QUESTIONNAIRE_EXPORT_STATUS = {
  PROCESSING: 'processing',
  COMPLETE: 'complete',
};

export const DEVICE_TYPES = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const BREAKPOINTS = {
  MOBILE: 320,
  TABLET: 768,
  DESKTOP: 1200,
};

export const LIST_ITEM_VARIANTS = {
  initial: {
    y: -10,
    opacity: 0,
  },
  fadeIn: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.25,
      staggerChildren: 0.25,
    },
  },
};

export const ON_BOARDING_FLOW_LOCAL_STORAGE_KEY = 'isOnBoardingFlow';
export const TS_ADMIN_BRANDING_URL = 'https://app.trustcloud.ai/trustshare/manage/branding';

// This constant is used to display custom controls, if a customer program has any.
export const OTHER_CONTROLS = 'Other Controls';

// These constants are used to display Evaluation Frequency of control.
export const EVIDENCE_FREQUENCY = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  SEMIANNUAL: 'semi-annual',
  ANNUAL: 'annual',
};

export const EVIDENCE_FREQUENCY_MAP = {
  [EVIDENCE_FREQUENCY.WEEKLY]: 'Weekly',
  [EVIDENCE_FREQUENCY.MONTHLY]: 'Monthly',
  [EVIDENCE_FREQUENCY.QUARTERLY]: 'Quarterly',
  [EVIDENCE_FREQUENCY.SEMIANNUAL]: 'Semi-Annual',
  [EVIDENCE_FREQUENCY.ANNUAL]: 'Annual',
};

export const TRUSTCLOUD_WEBSITE_URL = 'https://www.trustcloud.ai/';

export const NDA_ACKNOWLEDGEMENT_STEPS = {
  WELCOME: 'welcome',
  NDA_AGREEMENT: 'nda-agreement',
  NDA_REJECTION: 'nda-rejection',
};

export const CONTACT_US_REQUEST_TYPES = {
  CONTACT_US: 'contactUs',
  PROSPECT_EMAIL: 'prospectEmailRequest',
};

export const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const USER_TYPE = {
  TEAM_MEMBER: 'team_member',
  TEMPORARY_GUEST: 'temporary_guest',
};

export const FLAG_IMAGE_BASE_PATH = 'https://flagcdn.com/h20/';

// This is used in Policy and Control pages in standard mappings section as table headers
export const COMPLIANCE_MAPPING_COLUMN_NAMES = {
  STANDARD: 'Standard',
  CATEGORY: 'Category',
  REQUIREMENTS: 'Number of Requirements Satisfied',
};

export const STANDARDS_WITHOUT_DISPLAY_IDENTIFIER = [
  COMPLIANCE_STANDARDS.CMMC_L1,
  COMPLIANCE_STANDARDS.CMMC_L2,
  COMPLIANCE_STANDARDS.HIPAA,
  COMPLIANCE_STANDARDS.NIST_CSF,
  COMPLIANCE_STANDARDS.NIST_SP_800_171,
];

export const COMPLIANCE_STANDARD_SECTION_DESCRIPTOR = {
  [COMPLIANCE_STANDARDS.HIPAA]: {
    singular: 'Activity',
    plural: 'Activities',
  },
  [COMPLIANCE_STANDARDS.ISO27001]: {
    singular: 'Section',
    plural: 'Sections',
  },
  [COMPLIANCE_STANDARDS.SOC2]: {
    singular: 'Criterion',
    plural: 'Criteria',
  },
  [COMPLIANCE_STANDARDS.CMMC_L1]: {
    singular: 'Practice',
    plural: 'Practices',
  },
  [COMPLIANCE_STANDARDS.ISO9001]: {
    singular: 'Section',
    plural: 'Sections',
  },
  [COMPLIANCE_STANDARDS.NIST_CSF]: {
    singular: 'Category',
    plural: 'Categories',
  },
  [COMPLIANCE_STANDARDS.CMMC_L2]: {
    singular: 'Practice',
    plural: 'Practices',
  },
  [COMPLIANCE_STANDARDS.NIST_SP_800_171]: {
    singular: 'Domain',
    plural: 'Domains',
  },
  [COMPLIANCE_STANDARDS.SOX_ITGC]: {
    singular: 'Section',
    plural: 'Sections',
  },
  [COMPLIANCE_STANDARDS.ISO27701]: {
    singular: 'Section',
    plural: 'Sections',
  },
  [COMPLIANCE_STANDARDS.ISO_27701_PROCESSOR]: {
    singular: 'Section',
    plural: 'Sections',
  },
  [COMPLIANCE_STANDARDS.ISO_27701_CONTROLLER]: {
    singular: 'Section',
    plural: 'Sections',
  },
  [COMPLIANCE_STANDARDS.ISO_27001_2022]: {
    singular: 'Section',
    plural: 'Sections',
  },
};

export const STANDARD_CONTROL_COLUMN_NAME = {
  [COMPLIANCE_STANDARDS.SOC2]: 'SOC 2 Criteria',
  [COMPLIANCE_STANDARDS.HIPAA]: 'HIPAA Requirement Identifier',
  [COMPLIANCE_STANDARDS.ISO27001]: 'ISO 27001 Section',
  [COMPLIANCE_STANDARDS.CMMC_L1]: 'CMMC Level 1 Practices',
  [COMPLIANCE_STANDARDS.ISO9001]: 'ISO 9001 Section',
  [COMPLIANCE_STANDARDS.NIST_CSF]: 'NIST CSF Category',
  [COMPLIANCE_STANDARDS.CMMC_L2]: 'CMMC Level 2 Practices',
  [COMPLIANCE_STANDARDS.NIST_SP_800_171]: 'NIST SP 800-171 Domain',
  [COMPLIANCE_STANDARDS.ISO27701]: 'ISO 27701 Section',
  [COMPLIANCE_STANDARDS.ISO_27701_PROCESSOR]: 'ISO 27701 Processor Section',
  [COMPLIANCE_STANDARDS.ISO_27701_CONTROLLER]: 'ISO 27701 Controller Section',
};

export const POLICY_TABLE_HEADERS = ['Policy ID', 'Policy Name', 'Policy Description'];

export const CONTROL_TABLE_HEADERS = ['Control ID', 'Control Name', 'Mapping Verification'];

export const CONTROL_STATES = {
  ADOPTED: 'adopted',
};

export const PATHS_VISIBLE_FOR_PRIVATE_SITE = [
  '/',
  '/login',
  '/join',
  '/password-reset',
  '/request-access',
  '/home',
  '/create-account',
  '/connect',
];

export const PAGE_ROUTES = {
  REQUEST_ACCESS: '/request-access',
};

export const RESTRICTED_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'mail.com',
  'zoho.com',
  'protonmail.com',
  'yandex.com',
  'tutanota.com',
  'icloud.com',
  'gmx.com',
  'hushmail.com',
  'inbox.com',
  'mailinator.com',
  'tempmail.net',
  'guerrillamail.com',
  '10minutemail.com',
  'dispostable.com',
  'mailcatch.com',
  'throwawaymail.com',
  'sharklasers.com',
  'yopmail.com',
  'getairmail.com',
  'spamdomain.com',
  'freemailservice.com',
  'shadydomain.net',
  '123.com163.com',
  '126.com',
  'qq.com',
  'mail.ru',
  'rambler.ru',
  '21cn.com',
  'sina.com',
  'sohu.com',
  'comcast.net',
  'att.net',
  'verizon.net',
  'msn.com',
  'hotmail.com',
  'live.com',
  'naver.com',
  'daum.net',
  'gmx.de',
  'web.de',
  'orange.fr',
];

export const USER_TRACKING_COOKIE_NAME = 'userTracking';
export const USER_TRACKING_COOKIE_HEADER_NAME = 'X-TrustCloud-User-Tracking';
export const USER_TRACKING_COOKIE_MAX_AGE = 400 * 24 * 60 * 60 * 1000; // 400 days

export const TRUSTSHARE_NOTIFICATION_TYPE = {
  GENERAL: 'general',
  COMPLIANCE: 'compliance',
  INCIDENT: 'incident',
};

export const TRUSTSHARE_NOTIFICATION_TYPE_LABELS = {
  [TRUSTSHARE_NOTIFICATION_TYPE.COMPLIANCE]: 'Compliance',
  [TRUSTSHARE_NOTIFICATION_TYPE.GENERAL]: 'General',
  [TRUSTSHARE_NOTIFICATION_TYPE.INCIDENT]: 'Incident',
};

export const ROLE = {
  COMPLIANCE_ADMIN: 'Compliance Admin',
  GROUP_OWNER: 'Group Owner',
  COMPLIANCE_TEAM_MEMBER: 'Compliance Team Member',
  AUDITOR: 'Auditor',
  TRUST_SHARE_USER: 'Trust Share User',
  RESPOND_COLLABORATOR: 'Respond Collaborator',
  TRUST_SHARE_ADMIN: 'Trust Share Admin',
  EMPLOYEE: 'Employee',
  RISK_ADMIN: 'Risk Admin',
  RISK_OWNER: 'Risk Owner',
  TRUSTCLOUD_API: 'TrustCloud API',
  TRUST_API_USER: 'Trust API User',
};

// these standards are excluded from compliance mapping since they are incomplete or not applicable
export const EXCLUDED_STANDARDS_FROM_COMPLIANCE_MAPPING = [COMPLIANCE_STANDARDS.CCPA];
