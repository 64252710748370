import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Pagination } from '@kintent/glide';
import { Document, Page, pdfjs } from 'react-pdf';

import { usePolicyPDF } from 'lib/state';
import { SpaceAround } from '../design-system';
import { Flex } from './Flex';
import { Spinner } from './Spinner';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/scripts/pdf.worker.min.js`;

const StyledPdfWrapper = styled.div`
  position: relative;
  min-height: 718px;
  width: min-content;

  .page {
    max-width: max-content;
    border: 1px solid ${({ theme }) => theme.palette.silver};
    padding: 8px;
  }

  .loadingPage {
    max-width: max-content;
    border: 1px solid ${({ theme }) => theme.palette.silver};
    padding: 8px;
    position: absolute !important;
    z-index: 1;
  }
`;

const SpinnerWrapper = styled(Flex)`
  height: 700px;
  width: 100%;
  position: absolute;
  z-index: 1;
`;

const LoadingSpinner = () => (
  <SpinnerWrapper
    alignItems="center"
    justifyContent="center"
  >
    <Spinner />
  </SpinnerWrapper>
);

function PDFViewer({ policyId, onPageChange }) {
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [documentIsLoaded, setDocumentIsLoaded] = useState(false);
  const { blob } = usePolicyPDF(policyId);
  const handleDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber(offset);
    return offset;
  };

  const updateRenderedPageNumber = () => {
    setRenderedPageNumber(pageNumber);

    if (!documentIsLoaded) {
      setDocumentIsLoaded(true);
    }
  };

  useEffect(() => {
    // optional function to call when page is updated
    if (onPageChange) {
      onPageChange(pageNumber, totalPages);
    }
  }, [totalPages, documentIsLoaded, pageNumber, renderedPageNumber]);

  const isPageLoading = pageNumber !== renderedPageNumber;

  return (
    <>
      <StyledPdfWrapper
        as={SpaceAround}
        bottom="28px"
      >
        {!documentIsLoaded && <LoadingSpinner />}
        <Document
          file={blob}
          loading=""
          onLoadSuccess={handleDocumentLoadSuccess}
        >
          {isPageLoading && renderedPageNumber ? (
            <Page
              key={renderedPageNumber}
              pageNumber={renderedPageNumber}
              height={700}
              className="loadingPage"
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ) : null}
          <Page
            key={pageNumber}
            pageNumber={pageNumber}
            height={700}
            className="page"
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onRenderSuccess={updateRenderedPageNumber}
          />
        </Document>
      </StyledPdfWrapper>

      {totalPages > 1 && (
        <Pagination
          initialPage={1}
          onPageChange={changePage}
          pageSize={1}
          totalCount={totalPages}
        />
      )}
    </>
  );
}

export default PDFViewer;
